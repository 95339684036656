import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useCart} from '../context/CartContext';
import {usePostcode} from '../context/PostcodeContext';

const RightModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${({isOpen}) => (isOpen ? '0' : '-100%')};
  width: 75%;
  max-width: 300px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
`;

const CloseButton = styled(CloseIcon)`
  cursor: pointer;
`;

const ConfirmationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ConfirmationText = styled.p`
  font-size: 14px;
  color: black;
  margin-left: 10px;
`;

const PostcodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PostcodeText = styled.p`
  font-size: 14px;
  color: black;
  margin-bottom: 5px;
`;

const ClearCartButton = styled.button`
  border: none;
  background-color: white;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: black;
  padding: 0;

  @media (min-width: 768px) {
    &:hover {
      color: black;
    }
  }
`;

const DeleteCartIcon = styled(DeleteOutlineIcon)`
  font-size: 17px !important;
  margin-left: 5px;  /* Adjust spacing between text and icon */
`;

const ClearCartContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  color: black;
`;

const Header = styled.h2`
  font-size: 16px;
  color: #333;
  margin: 0;
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
`;

const ProductTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const ProductImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 5px;
  object-fit: cover;
`;

const ProductInfo = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const ProductName = styled.h2`
  font-size: 12px;
  color: #333;
  margin: 0;
  font-weight: bold;
`;

const ProductTotalPrice = styled.p`
  font-size: 12px;
  color: #555;
  margin: 0;
  font-weight: bold;
`;

const QuantityDropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 60px; /* Adjust width to make room for the arrow */
`;

const QuantityDropdown = styled.select`
  font-size: 12px;
  padding: 5px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 35px;
  text-align: center;
  cursor: pointer;
  color: black;

  &:focus {
    border-color: #555;
    outline: none;
  }
`;

const DropdownArrow = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555;
  transform: translateY(-50%);
`;


const ProductSmallInfo = styled.p`
  font-size: 12px;
  color: #555;
  margin: 5px 0;
`

const Label = styled.span`
  color: black;
  font-weight: 1500;
`;

const TotalContainer = styled.div`
  padding-top: 20px;
  border-top: 1px solid #ccc;
  position: sticky;
  bottom: 0;
  background-color: white;
`;

const TotalText = styled.p`
  font-size: 20px;
  color: #333;
  margin: 20px 0;
  text-align: left;
`;

const CheckoutButton = styled.button`
  background-color: black;
  width: 100%;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 30px;

  &:hover {
    background-color: #333;
  }
`;

const DeleteButton = styled(DeleteOutlineIcon)`
  cursor: pointer;
  color: #888;
  margin-left: 10px;

  @media (min-width: 768px) {
    &:hover {
      color: red;
    }
  }
`;

const RightModal = ({isOpen, onClose}) => {
  const {cart, removeFromCart, updateCartItemQuantity, setCart} = useCart(); // Restored setCart
  const {postcode} = usePostcode();
  const [showConfirmation, setShowConfirmation] = useState(true);
  const modalRef = useRef(null);

  const handleCheckout = () => {
    window.location.href = '/kassa';
  };

  const handleClearCart = () => {
    setCart([]); // Clears the cart
  };

  const numberFormatter = new Intl.NumberFormat('sv-SE');

  const totalPrice = numberFormatter.format(
    cart.reduce((total, item) => {
      const itemPrice = item.price ? parseFloat(item.price.replace(/[^0-9.-]+/g, '')) : 0;
      return total + itemPrice * item.quantity;
    }, 0)
  );

  const handleQuantityChange = (productId, size, event) => {
    const newQuantity = parseInt(event.target.value);
    updateCartItemQuantity(productId, size, newQuantity);
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowConfirmation(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.style.overflow = '';
      document.removeEventListener('mousedown', handleClickOutside);
    }
  
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <RightModalContainer isOpen={isOpen} ref={modalRef}>
      <HeaderContainer>
        <Header>Varukorg</Header>
        <CloseButton onClick={onClose} />
      </HeaderContainer>


      <PostcodeContainer>
        <PostcodeText>
          Priset beräknas utifrån postnummer: <strong>{postcode}</strong>
        </PostcodeText>
      </PostcodeContainer>
      <ClearCartContainer>
        <ClearCartButton onClick={handleClearCart}>
          Töm varukorg
          <DeleteCartIcon />
        </ClearCartButton>
      </ClearCartContainer>

      <Divider />

      {cart.length === 0 ? (
        <p>Din varukorg är tom</p>
      ) : (
        <ScrollableContent>
          {cart.map((item, index) => (
            <ProductContainer key={index}>
              <ProductTopRow>
                <ProductImage src={item.product.imageUrl} alt={item.product.name} />
                <QuantityDropdownWrapper>
                  <QuantityDropdown
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(item.product.id, item.size, e)}
                  >
                    {[...Array(150).keys()].map(num => (
                      <option key={num + 1} value={num + 1}>
                        {num + 1}
                      </option>
                    ))}
                  </QuantityDropdown>
                  <DropdownArrow />
                </QuantityDropdownWrapper>
                <DeleteButton onClick={() => removeFromCart(item.product.id, item.size)} />
              </ProductTopRow>
              <ProductDetails>
                <ProductInfo>
                  <ProductName>{item.product.name}</ProductName>
                  <ProductTotalPrice>
                    {numberFormatter.format(
                        parseFloat(item.product.price.replace(/[^0-9.-]+/g, '')) * item.quantity,
                    )}{' '}
                    SEK
                  </ProductTotalPrice>
                </ProductInfo>
              </ProductDetails>
              {item.product.size && <ProductSmallInfo><Label>Storlek: </Label>{item.product.size}</ProductSmallInfo>}
              {item.product.price_type && <ProductSmallInfo><Label>Beställningsenhet: </Label>{item.product.price_type}</ProductSmallInfo>}
              {item.product.price_type && item.product.price && <ProductSmallInfo><Label>Pris: </Label>{item.product.price} / {item.product.price_type}</ProductSmallInfo>}              
            </ProductContainer>
          ))}
        </ScrollableContent>
      )}

      <TotalContainer>
        <TotalText>Totalt {totalPrice} SEK</TotalText>
        <CheckoutButton onClick={handleCheckout}>Till kassan</CheckoutButton>
      </TotalContainer>
    </RightModalContainer>
  );
};

export default RightModal;
