// src/App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import Products from './components/Products';
import ProductDetail from './components/ProductDetail';
import Checkout from './components/Checkout';
import Support from './components/Support';
import ArticlePage from './components/ArticlePage';
import GuideTemplate from './components/GuideTemplate'; // Import GuideTemplate
import { PostcodeProvider } from './context/PostcodeContext';
import { CartProvider } from './context/CartContext';
import ScrollToTop from './components/common/ScrollToTop.js';
import DevelopmentModal from './components/DevelopmentModal';
import AboutUs from './components/AboutUs';
import { Provider } from 'react-redux'; // Import the Provider component from react-redux
import store from './store/store'; // Import the Redux store


const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    background-color: #f5f5f5; /* Optional: Add a global background color */
  }

  /* Add any additional global styles here */
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.div`
  flex: 1;
`;

const App = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Provider store={store}> {/* Wrap the application with the Redux Provider */}
      <PostcodeProvider>
        <CartProvider>
          <Router>
            <ScrollToTop />
            <AppContainer>
              {showModal && <DevelopmentModal onClose={handleCloseModal} />}
              <Header />
              <MainContent>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/produkter" element={<Products />} />
                  <Route path="/produkter/:product_id" element={<ProductDetail />} />
                  <Route path="/kassa" element={<Checkout />} />
                  <Route path="/guider" element={<ArticlePage />} />
                  <Route path="/om-oss" element={<AboutUs />} />
                  {/* Dynamic Route to Load Markdown Files */}
                  <Route
                    path="/guider/:guideId"
                    element={<GuideTemplate />}
                  />

                  <Route path="/support" element={<Support />} />
                </Routes>
              </MainContent>
              <Footer />
            </AppContainer>
          </Router>
        </CartProvider>
      </PostcodeProvider>
    </Provider>
  );
};

export default App;
