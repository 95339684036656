// src/store/productsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Async thunk for fetching products
export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  if (process.env.NODE_ENV === 'development') {
    const { mockProducts } = await import('../components/mockProducts/mockProducts');
    return mockProducts.filter(product => product.in_assortment);
  } else {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/products`);
    const data = await response.json();
    return data.filter(product => product.in_assortment);
  }
});

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // Optional: Add any synchronous actions here (if needed)
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default productsSlice.reducer;
