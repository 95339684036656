// view/src/components/Header.js

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../store/productsSlice';
import { performSearch } from '../utils/searchLogic';
import {usePostcode} from '../context/PostcodeContext';
import {useCart} from '../context/CartContext';
import { menuItems } from './menuItems'; 
import logo from '../assets/logo.svg';
import PersonIcon from '@mui/icons-material/PersonOutlineOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import MenuIcon from '@mui/icons-material/MenuOutlined';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import ShippingIcon from '@mui/icons-material/LocalShippingOutlined';
import PostcodeModal from './PostcodeModal';
import RightModal from './RightModal';
import MenuContainer from './MenuContainer';

// Define the styled components

const FirstRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-top: 10px;
`;

const SecondRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid #DCDCDC; // This introduces the border
`;

const HeaderContainer = styled.header`
  background-color: ${({ isScrolled, forceWhiteHeader }) => (isScrolled || forceWhiteHeader ? 'white' : 'transparent')};
  color: ${({ isScrolled, forceWhiteHeader }) => (isScrolled || forceWhiteHeader ? 'black' : 'white')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: ${({ isScrolled, forceWhiteHeader }) => (isScrolled || forceWhiteHeader ? '1px solid #ccc' : 'transparent')};
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;

  @media (min-width: 768px) {

    width: 100%;
    right: 0;
    left: 0;
  }
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 768px) {
    gap: 20px;
    max-width: 100%;
    box-sizing: border-box;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-right: 30px;
  }
`;

const Logo = styled.img`
  height: 50px;
  margin-left: 10px;
  cursor: pointer;
  filter: ${({ isScrolled, forceWhiteHeader }) => (isScrolled || forceWhiteHeader ? 'none' : 'invert(1)')};

  @media (min-width: 768px) {
    height: 54px;
    margin-left: 30px;
  }
`;


const StyledMenuIcon = styled(MenuIcon)`
  color: ${({isScrolled, forceWhiteHeader }) => (isScrolled || forceWhiteHeader ? 'black' : 'white')};
  margin-right: 20px;
  margin-left: 4px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: ${({isScrolled, forceWhiteHeader }) => (isScrolled || forceWhiteHeader ? 'black' : 'white')};
  
  margin-right: 5px;

  @media (min-width: 768px) {
    margin-left: 20px;
  }
`;



const SearchBarContainer = styled.div`
  margin: 6px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 53px;

  font-size: 16px;

  @media (min-width: 768px) {
    margin-top: 0;
    flex-direction: column;
    z-index: 9999;
    position: relative;
    top: 0px;
    width: 65%;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;

  @media (min-width: 768px) {
  width: 100%;
  }
`;

const SearchBarIconInBar = styled(SearchIcon)`
  color: black;
  margin-right: 8px;
`;

const SearchBar = styled.input`
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 0;

  font-size: 16px;  // Ensure font size is at least 16px
  &::placeholder {
    color: black;
  }

`;

const SearchResultsContainer = styled.div`
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  margin-top: 5px;
  width: 100%;
  border: 0.2px solid black;  // Add a black border

    @media (min-width: 768px) {
    position: absolute;
    top: 100%;
    left: 1;
    z-index: 9999;
    opacity: 1;
  }
`;

const SearchResultItem = styled.div`
  display: flex;
  justify-content: space-between;  // Ensure the name and price are at opposite ends
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
`;

const SearchResultImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
`;

const SearchResultTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;  // Allow the text container to take up the remaining space
  color: black;
  text-decoration: none;
`;

const StyledLink = styled(Link)`
  color: black;  // Set the text color to black
  text-decoration: underline;  // Remove the underline

  &:hover {
    text-decoration: underline;  // Optionally, you can add underline on hover if desired
  }
`;

const SearchResultName = styled.div`
  flex-grow: 1;  // Ensure the name takes up as much space as possible
  color: black;
  text-decoration: none;  // Remove the underline
`;

const SearchResultPrice = styled.div`
  text-align: right;
  white-space: nowrap;  // Prevent the price from wrapping to a new line
  margin-left: 10px;  // Add space between the name and price
`;


const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: auto;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: 15px;

  @media (min-width: 768px) {
    margin-right: 1000px;
    justify-content: center;
    margin: 0px;
    max-width: 100px;
  }
`;

const StyledShippingIcon = styled(ShippingIcon)`

  @media (min-width: 768px) {
    font-size: 30px !important;
  }
`;


const ShippingText = styled.span`
  margin-left: 10px;
  color: ${({isScrolled, forceWhiteHeader }) => (isScrolled || forceWhiteHeader ? 'black' : 'white')};
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const CartContainer = styled.div`
  margin-right: 60px;
`;

const CartIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px;
  font-size: 30px !important;
`;

const StyledCartIcon = styled(ShoppingCartIcon)`
  color: ${({isScrolled, forceWhiteHeader }) => (isScrolled || forceWhiteHeader ? 'black' : 'white')};
  margin-left: 7px;
  font-size: 25px !important;
`;

const CartCountBadge = styled.div`
  position: absolute;
  top: -8px;
  right: -7px;
  background-color: blue;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

    @media (min-width: 768px) {

    }
`;

const PageWrapper = styled.div`

`;


const Nav = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 5px;

    @media (min-width: 768px) {
    gap: 5px;
    flex-wrap: nowrap;
    white-space: no-wrap;
    justify-content: center;
  }
`;

const MenuLink = styled(Link)`
  color: ${({ isScrolled, forceWhiteHeader }) => (isScrolled || forceWhiteHeader ? 'black' : 'white')};
  text-decoration: none;
  font-size: 18px;

  @media (min-width: 768px) {
  font-size: 14px;
  padding: 10px 20px;;
  }

  &:hover {
    background-color: #F8F8FF;
  }
`;


  // Function to check if screen is mobile
  const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      };
    }, []);

    return width <= 768; // Return true if width is less than or equal to 768px (mobile size)
  };

const Header = ({ forceWhiteHeader }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useCheckMobileScreen();
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rightModalOpen, setRightModalOpen] = useState(false); // State for RightModal
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const productStatus = useSelector(state => state.products.status);
  const {postcode} = usePostcode();
  const {cart} = useCart(); // Retrieve cart from context

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleRightModal = () => {
    setRightModalOpen(!rightModalOpen);
  };

  const handleSearchIconClick = (e) => {
    e.stopPropagation();
    setIsSearchVisible((prev) => !prev);
    setSearchQuery('');  // Clear the search query
    setSearchResults([]);  // Clear the search results
  };

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }

    const searchContainer = document.querySelector('#search-container');
    if (searchContainer) {
      const offset = searchContainer.offsetTop + searchContainer.offsetHeight;
      if (window.pageYOffset > offset) {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };

  useEffect(() => {
    if (productStatus === 'idle') {
      dispatch(fetchProducts());
    }
  }, [productStatus, dispatch]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 0) {
      const results = performSearch(query);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!document.getElementById('search-container')?.contains(event.target)) {
        setIsSearchVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  const handleMenuItemClick = (path) => {
    navigate(path);
    setMenuOpen(false);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleCartClick = () => {
    toggleRightModal(); // Open RightModal when CartIcon is clicked
  };

  const productCount = cart.length; // Calculate the number of different products

  const mobileLayout = (
    <>
    <HeaderContainer isScrolled={isScrolled} forceWhiteHeader={forceWhiteHeader} aria-label="Main Navigation">
        <TopRow>
          <Nav aria-label="Primary Navigation">
            <button
              aria-label="Open Menu"
              onClick={toggleMenu}
              style={{background: 'none', border: 'none', padding: 0}}
            >
              <StyledMenuIcon isScrolled={isScrolled} forceWhiteHeader={forceWhiteHeader} />
            </button>
          </Nav>
          <Logo
            src={logo}
            alt="Nordic Soil Logo"
            onClick={handleLogoClick}
            isScrolled={isScrolled} forceWhiteHeader={forceWhiteHeader}
          />
          <Nav aria-label="Secondary Navigation">
            <button
              aria-label="Toggle Search"
              onClick={handleSearchIconClick}
              style={{background: 'none', border: 'none', padding: 0}}
            >
              <StyledSearchIcon isScrolled={isScrolled} forceWhiteHeader={forceWhiteHeader} />
            </button>
            <button
              aria-label="View Cart"
              onClick={handleCartClick}
              style={{background: 'none', border: 'none', padding: 0}}
            >
              <CartIconContainer>
                <StyledCartIcon isScrolled={isScrolled} forceWhiteHeader={forceWhiteHeader} />
                {productCount > 0 && <CartCountBadge>{productCount}</CartCountBadge>}
              </CartIconContainer>
            </button>
          </Nav>
        </TopRow>
        {isSearchVisible && (
          <SearchBarContainer id="search-container">
            <SearchWrapper>
              <SearchBarIconInBar />
              <SearchBar
                type="text"
                placeholder="Vad letar du efter?"
                aria-label="Search Products"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </SearchWrapper>
            {searchResults.length > 0 && (
              <SearchResultsContainer>
                {searchResults.map((result, index) => (
                  <SearchResultItem key={index}>
                    {result.product_img && (
                      <SearchResultImage 
                        src={`/assets/product_images/${result.product_img}`} 
                        alt={result.product_name} 
                      />
                    )}
                    <SearchResultTextContainer>
                      <SearchResultName>
                        <StyledLink 
                          to={result.url || `/produkter/${result.product_id}`}
                          onClick={() => {
                            setSearchQuery('');  // Clear search query when a result is clicked
                            setIsSearchVisible(false);  // Hide the search bar
                            setSearchResults([]);  // Clear the search results
                          }}
                        >
                          {result.product_name || result.title}
                        </StyledLink>
                      </SearchResultName>
                      <SearchResultPrice>
                        Från {result.product_price} kr
                      </SearchResultPrice>
                    </SearchResultTextContainer>
                  </SearchResultItem>
                ))}
              </SearchResultsContainer>
            )}
          </SearchBarContainer>
        )}
        <InfoContainer onClick={() => setModalOpen(true)} role="button" aria-label="Shipping Information">
          <ShippingIcon aria-hidden="true" />
          <ShippingText isScrolled={isScrolled} forceWhiteHeader={forceWhiteHeader}>
            <strong>{postcode || 'Ange ditt postnummer'}</strong>
          </ShippingText>
        </InfoContainer>
      </HeaderContainer>
      <MenuContainer
        isOpen={menuOpen}
        toggleMenu={toggleMenu}
        handleMenuItemClick={handleMenuItemClick}
      />
      <PostcodeModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
      <RightModal isOpen={rightModalOpen} onClose={toggleRightModal} />
    </>
  );

  const desktopLayout = (
    <PageWrapper>
      <HeaderContainer isScrolled={true} forceWhiteHeader={true} aria-label="Main Navigation">
        {/* First Row: Logo, SearchBarContainer, CartContainer */}
        <FirstRow>
          <Logo
            src={logo}
            alt="Nordic Soil Logo"
            onClick={handleLogoClick}
            isScrolled={true}
            forceWhiteHeader={true}
          />
          <SearchBarContainer id="search-container">
            <SearchWrapper>
              <SearchBarIconInBar />
              <SearchBar
                type="text"
                placeholder="Vad letar du efter?"
                aria-label="Search Products"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </SearchWrapper>
            {searchResults.length > 0 && (
              <SearchResultsContainer>
                {searchResults.map((result, index) => (
                  <SearchResultItem key={index}>
                    {result.product_img && (
                      <SearchResultImage
                        src={`/assets/product_images/${result.product_img}`}
                        alt={result.product_name}
                      />
                    )}
                    <SearchResultTextContainer>
                      <SearchResultName>
                        <StyledLink
                          to={result.url || `/produkter/${result.product_id}`}
                          onClick={() => {
                            setSearchQuery('');  // Clear search query when a result is clicked
                            setIsSearchVisible(false);  // Hide the search bar
                            setSearchResults([]);  // Clear the search results
                          }}
                        >
                          {result.product_name || result.title}
                        </StyledLink>
                      </SearchResultName>
                      <SearchResultPrice>
                        Från {result.product_price} kr
                      </SearchResultPrice>
                    </SearchResultTextContainer>
                  </SearchResultItem>
                ))}
              </SearchResultsContainer>
            )}
          </SearchBarContainer>
          <InfoContainer onClick={() => setModalOpen(true)} role="button" aria-label="Shipping Information">
            <StyledShippingIcon aria-hidden="true" />
            <ShippingText isScrolled={true} forceWhiteHeader={true}>
              {postcode || 'Ange ditt postnummer'}
            </ShippingText>
          </InfoContainer>
          <CartContainer>
            <button
              aria-label="View Cart"
              onClick={handleCartClick}
              style={{ background: 'none', border: 'none', padding: 0 }}
            >
              <CartIconContainer>
                <StyledCartIcon isScrolled={true} forceWhiteHeader={true} />
                {productCount > 0 && <CartCountBadge>{productCount}</CartCountBadge>}
              </CartIconContainer>
            </button>
          </CartContainer>
        </FirstRow>
  
        {/* Second Row: NavLinks, InfoContainer */}
        <SecondRow>
          <Nav aria-label="Secondary Navigation">
            {menuItems.map((item) => (
              <MenuLink to={item.link} key={item.text} isScrolled={true} forceWhiteHeader={true}>
                {item.text}
              </MenuLink>
            ))}
          </Nav>
        </SecondRow>
      </HeaderContainer>
      <PostcodeModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
      <RightModal isOpen={rightModalOpen} onClose={toggleRightModal} />
    </PageWrapper>
  );

  return (
    <>
      {isMobile ? mobileLayout : desktopLayout}
    </>
  );
};

export default Header;