//view/src/components/Checkout.js

import React, { useState } from 'react';
import styled from 'styled-components';
import { useCart } from '../context/CartContext';
import { usePostcode } from '../context/PostcodeContext';
import PostcodeModal from './PostcodeModal';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; // Import the trash bin icon

const Container = styled.div`
  margin: 15px;
`;

const Banner = styled.div`
  width: 100%;
  height: 120px; /* Adjust the height as needed */
  background: url(/assets/lastbil_lastas.jpg) no-repeat center center/cover;
  position: relative;
  margin-bottom: 20px;
`;

const BannerOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for better text visibility */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const InfoContainer = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`;

const EmptyCartMessage = styled.div`
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-top: 50px;
`;

const CartText = styled.h2`
  font-size: 24px;
  color: black;
  margin-bottom: 5px;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`;

const ProductDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ProductImage = styled.img`
  width: 100px;
  height: auto;
  margin-right: 20px;
`;

const ProductInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;


const ProductName = styled.h2`
  font-size: 18px;
  color: #333;
  margin: 0;
`;

const ProductTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuantityDropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 80px;
`;

const QuantityDropdown = styled.select`
  font-size: 18px;
  padding: 5px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 55px;
  text-align: center;
  cursor: pointer;
  color: black;

  &:focus {
    border-color: #555;
    outline: none;
  }
`;

const DropdownArrow = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555;
  transform: translateY(-50%);
`;

const ProductTotalPrice = styled.p`
  font-size: 16px; /* Adjusted to match the product name size */
  color: #555;
  margin: 0;
  font-weight: bold;
`;

const ProductSmallInfo = styled.p`
  font-size: 12px;
  color: #555;
  margin: 5px 0;
`;

const Label = styled.span`
  color: black;
  font-weight: bold;
`;


const ProductQuantity = styled.p`
  font-size: 16px;
  color: #666;
`;

const DeleteButton = styled(DeleteOutlineIcon)`
  cursor: pointer;
  color: #888;
  margin-left: 10px;
`;

const OptionContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const OptionBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const PostcodeContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`;

const PostcodeText = styled.p`
  font-size: 16px;
  color: #666;
  margin-left: 10px;
`;

const ChangeButton = styled.button`
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
`;

const TotalText = styled.p`
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
`;

const FlexIt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const ContinueButton = styled.button`
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  width: 90%;
  &:hover {
    background-color: #555;
  }
`;

const CustomerInfoContainer = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const InputField = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid ${({ isInvalid }) => (isInvalid ? 'red' : '#ccc')};
  background-color: ${({ isInvalid }) => (isInvalid ? 'rgba(255, 0, 0, 0.1)' : 'white')};
  border-radius: 5px;
  &::placeholder {
    color: ${({ isInvalid }) => (isInvalid ? 'red' : '#aaa')};
    opacity: ${({ isInvalid }) => (isInvalid ? '0.7' : '1')};
  }
`;

const CheckboxContainer = styled.div`
  margin: 15px 0;
`;

const Checkout = () => {
  const { cart, removeFromCart, updateCartItemQuantity } = useCart();
  const { postcode, setPostcode } = usePostcode();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [useDifferentDeliveryAddress, setUseDifferentDeliveryAddress] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    address: '',
    postalCode: postcode, // Initialize with current postcode
    city: '',
    phoneNumber: '',
    email: '',
  });
  const [deliveryFormValues, setDeliveryFormValues] = useState({
    firstName: '',
    lastName: '',
    address: '',
    postalCode: '',
    city: '',
    phoneNumber: '',
  });

  const handleRemoveFromCart = (productId, size) => {
    removeFromCart(productId, size); // Make sure to pass both productId and size
  };

  const handleQuantityChange = (productId, size, event) => {
    const newQuantity = parseInt(event.target.value);
    updateCartItemQuantity(productId, size, newQuantity);
  };
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (value.trim() !== '') {
      setFormErrors({
        ...formErrors,
        [name]: '',
      });
    }
  };

  const handleDeliveryInputChange = (e) => {
    const { name, value } = e.target;
    setDeliveryFormValues({
      ...deliveryFormValues,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};
    if (!formValues.firstName) errors.firstName = 'Förnamn är obligatoriskt';
    if (!formValues.lastName) errors.lastName = 'Efternamn är obligatoriskt';
    if (!formValues.address) errors.address = 'Adress är obligatoriskt';
    if (!formValues.postalCode) errors.postalCode = 'Postnummer är obligatoriskt';
    if (!formValues.city) errors.city = 'Ort är obligatoriskt';
    if (!formValues.phoneNumber) errors.phoneNumber = 'Mobilnummer är obligatoriskt';
    if (!formValues.email) {
      errors.email = 'E-post är obligatoriskt';
    } else if (!validateEmail(formValues.email)) {
      errors.email = 'Ogiltig e-postadress';
    }
    
    if (useDifferentDeliveryAddress) {
      if (!deliveryFormValues.firstName) errors.deliveryFirstName = 'Förnamn är obligatoriskt';
      if (!deliveryFormValues.lastName) errors.deliveryLastName = 'Efternamn är obligatoriskt';
      if (!deliveryFormValues.address) errors.deliveryAddress = 'Adress är obligatoriskt';
      if (!deliveryFormValues.postalCode) errors.deliveryPostalCode = 'Postnummer är obligatoriskt';
      if (!deliveryFormValues.city) errors.deliveryCity = 'Ort är obligatoriskt';
      if (!deliveryFormValues.phoneNumber) errors.deliveryPhoneNumber = 'Mobilnummer är obligatoriskt';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleContinue = async () => {
    if (validateForm()) {
      // Use the delivery postal code if available, otherwise use the customer info postal code
      const postalCode = useDifferentDeliveryAddress && deliveryFormValues.postalCode 
                        ? deliveryFormValues.postalCode 
                        : formValues.postalCode;

      const closestSuppliers = await findClosestSuppliers(formValues.address);
  
      if (closestSuppliers) {
        // Use the closest suppliers to update the UI, e.g., show the closest location
        console.log('Closest suppliers:', closestSuppliers);
        // Here, you could set state or update the form values with the closest supplier details
      }
  
      // Proceed to payment or the next step
      console.log('Form is valid, proceeding to payment...');
    } else {
      console.log('Form is invalid, please fill in all required fields.');
    }
  };

  const totalPrice = cart
    .reduce((total, item) => {
      const itemPrice = item.product.price ? parseFloat(item.product.price.replace(/[^0-9.-]+/g, '')) : 0;
      return total + itemPrice * item.quantity;
    }, 0)
    .toFixed(0);

  return (
    <>
      <Banner>
        <BannerOverlay />
      </Banner>
      <Container>
        <InfoContainer>
          <Header>Lösvikt eller storsäck?</Header>
          <p>Välj det bästa alternativet för ditt material.</p>
        </InfoContainer>

        {cart.length === 0 ? (
          <EmptyCartMessage>
            Ingenting här än! Se till att samla på dig lite för din fina trädgård eller uppfart så ses vi här snart igen
          </EmptyCartMessage>
        ) : (
          <>
            <PostcodeContainer>
              <PostcodeText>
                Priset beräknas utifrån följande postnummer: <strong>{postcode}</strong>
              </PostcodeText>
              <ChangeButton onClick={() => setIsModalOpen(true)}>Ändra</ChangeButton>
            </PostcodeContainer>
            <CartText>Varukorg</CartText>
            {cart.map((item, index) => (
              <ProductContainer key={index}>
                <ProductTopRow>
                  <ProductImage src={item.product.imageUrl} alt={item.product.name} />
                  <QuantityDropdownWrapper>
                    <QuantityDropdown
                      value={item.quantity}
                      onChange={(e) => handleQuantityChange(item.product.id, item.size, e)}
                    >
                      {[...Array(150).keys()].map(num => (
                        <option key={num + 1} value={num + 1}>
                          {num + 1}
                        </option>
                      ))}
                    </QuantityDropdown>
                    <DropdownArrow />
                  </QuantityDropdownWrapper>
                  <DeleteButton onClick={() => handleRemoveFromCart(item.product.id, item.size)} />
                </ProductTopRow>
                <ProductDetails>
                  <ProductInfo>
                    <ProductName>{item.product.name}</ProductName>
                    <ProductTotalPrice>
                      {parseFloat(item.product.price.replace(/[^0-9.-]+/g, '')) * item.quantity} SEK
                    </ProductTotalPrice>
                  </ProductInfo>
                </ProductDetails>
                {item.product.size && <ProductSmallInfo><Label>Storlek: </Label>{item.product.size}</ProductSmallInfo>}
                {item.product.price_type && <ProductSmallInfo><Label>Beställningsenhet: </Label>{item.product.price_type}</ProductSmallInfo>}
                {item.product.price_type && item.product.price && <ProductSmallInfo><Label>Pris: </Label>{item.product.price} / {item.product.price_type}</ProductSmallInfo>}
              </ProductContainer>
            ))}
            <TotalText>Totalt för varor: <strong>{totalPrice} kr</strong></TotalText>

            <CustomerInfoContainer>
              <CartText>Kunduppgifter</CartText>
              
              <label>Förnamn</label>
              <InputField
                type="text"
                name="firstName"
                placeholder="Förnamn *"
                value={formValues.firstName}
                onChange={handleInputChange}
                isInvalid={!!formErrors.firstName}
              />

              <label>Efternamn</label>
              <InputField
                type="text"
                name="lastName"
                placeholder="Efternamn *"
                value={formValues.lastName}
                onChange={handleInputChange}
                isInvalid={!!formErrors.lastName}
              />

              <label>Adress</label>
              <InputField
                type="text"
                name="address"
                placeholder="Adress *"
                value={formValues.address}
                onChange={handleInputChange}
                isInvalid={!!formErrors.address}
              />

              <label>Postnummer</label>
              <InputField
                type="text"
                name="postalCode"
                placeholder="Postnummer *"
                value={formValues.postalCode}
                onChange={handleInputChange}
                isInvalid={!!formErrors.postalCode}
              />

              <label>Mobilnummer</label>
              <InputField
                type="text"
                name="phoneNumber"
                placeholder="Mobilnummer *"
                value={formValues.phoneNumber}
                onChange={handleInputChange}
                isInvalid={!!formErrors.phoneNumber}
              />

              <label>E-post</label>
              <InputField
                type="email"
                name="email"
                placeholder="E-post *"
                value={formValues.email}
                onChange={handleInputChange}
                isInvalid={!!formErrors.email}
              />
            </CustomerInfoContainer>

            <CheckboxContainer>
              <input
                type="checkbox"
                checked={useDifferentDeliveryAddress}
                onChange={(e) => setUseDifferentDeliveryAddress(e.target.checked)}
              />
              <label>Använd en annan leveransadress</label>
            </CheckboxContainer>

            {useDifferentDeliveryAddress && (
              <CustomerInfoContainer>
                <CartText>Leveransuppgifter</CartText>

                <label>Förnamn</label>
                <InputField
                  type="text"
                  name="firstName"
                  placeholder="Förnamn *"
                  value={deliveryFormValues.firstName}
                  onChange={handleDeliveryInputChange}
                  isInvalid={!!formErrors.deliveryFirstName}
                />

                <label>Efternamn</label>
                <InputField
                  type="text"
                  name="lastName"
                  placeholder="Efternamn *"
                  value={deliveryFormValues.lastName}
                  onChange={handleDeliveryInputChange}
                  isInvalid={!!formErrors.deliveryLastName}
                />

                <label>Adress</label>
                <InputField
                  type="text"
                  name="address"
                  placeholder="Adress *"
                  value={deliveryFormValues.address}
                  onChange={handleDeliveryInputChange}
                  isInvalid={!!formErrors.deliveryAddress}
                />

                <label>Postnummer</label>
                <InputField
                  type="text"
                  name="postalCode"
                  placeholder="Postnummer *"
                  value={deliveryFormValues.postalCode}
                  onChange={handleDeliveryInputChange}
                  isInvalid={!!formErrors.deliveryPostalCode}
                />

                <label>Ort</label>
                <InputField
                  type="text"
                  name="city"
                  placeholder="Ort *"
                  value={deliveryFormValues.city}
                  onChange={handleDeliveryInputChange}
                  isInvalid={!!formErrors.deliveryCity}
                />

                <label>Mobilnummer</label>
                <InputField
                  type="text"
                  name="phoneNumber"
                  placeholder="Mobilnummer *"
                  value={deliveryFormValues.phoneNumber}
                  onChange={handleDeliveryInputChange}
                  isInvalid={!!formErrors.deliveryPhoneNumber}
                />
              </CustomerInfoContainer>
            )}

            <FlexIt>
              <ContinueButton onClick={handleContinue}>Fortsätt till betalning</ContinueButton>
            </FlexIt>
          </>
        )}
        <PostcodeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </Container>
    </>
  );
};

export default Checkout;
