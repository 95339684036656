// src/utils/searchLogic.js

import store from '../store/store'; // Import the Redux store

// Mock data for guides (replace with actual guide data fetching if needed)
const mockGuides = [
  { id: 1, title: "Guide 1", content: "Content for guide 1", url: "/guider/1" },
  { id: 2, title: "Guide 2", content: "Content for guide 2", url: "/guider/2" },
  // Add more mock guides here...
];

/**
 * Filters products and guides based on the search query.
 * @param {string} query - The search query entered by the user.
 * @returns {Array} - The filtered list of products and guides matching the query.
 */
export const performSearch = (query) => {
    const state = store.getState(); // Get the current state from the Redux store
    const products = state.products.items || []; // Fallback to empty array if undefined
    const lowercasedQuery = query.toLowerCase();
  
    const filteredProducts = products.filter(product => {
      const productName = product.product_name ? product.product_name.toLowerCase() : '';
      return productName.includes(lowercasedQuery);
    }).map(product => ({
      product_name: product.product_name,
      product_price: product.product_price,
      product_img: product.product_img || '', // Include the product image
      url: `/produkter/${product.product_id}` // Assuming `product_id` is used to create the product detail URL
    }));
  
    const filteredGuides = mockGuides.filter(guide =>
      guide.title.toLowerCase().includes(lowercasedQuery)
    ).map(guide => ({
      title: guide.title,
      url: guide.url
    }));
  
    return [...filteredProducts, ...filteredGuides];
  };
