// src/components/HomePage.js

import React, {useRef, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import logo from '../assets/logo.svg';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

// Main container for the entire page
const MainContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
   overflow-y: auto;   // Allow vertical scrolling
`;

// Overlay styling for background images
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

// Animation for image zoom-in effect
const zoomIn = keyframes`
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// Styled component for the first section with an image
const FirstContainer = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;
  animation: ${zoomIn} 1.5s ease forwards;
    overflow: hidden; // Prevent internal overflow
`;

// Image element for the first container
const FirstImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

// Styled component for the second section with an image
const SecondContainer = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
    overflow: hidden; // Prevent internal overflow
`;

// Image element for the second container
const SecondImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

// Styled component for the third section with an image
const ThirdContainer = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
    overflow: hidden; // Prevent internal overflow
`;

// Image element for the third container
const ThirdImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

// Logo container with header tag for semantic structure
const LogoContainer = styled.header`
  width: 100%;
  height: 28vh;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Logo image styling
const Logo = styled.img`
  height: 100px;

  @media (min-width: 768px) {
    height: 60px;
  }
`;

// Text styling for the logo slogan
const LogoText = styled.h1`
  font-size: 24px;
  margin-top: 10px;
`;

// Link styling for the "About Us" section
const AboutUs = styled(Link)`
  font-size: 18px;
  color: black;
  margin-top: 14px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

// Scrollable products section with semantic structure
const ScrollableProducts = styled.section`
  width: 100%;
  height: calc(48vh);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
`;

// Container for the recommended products
const RecommendedContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

// Scrollable area for product cards
const ScrollableArea = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding: 10px 0;
  padding-left: 10px;
  padding-right: 10px;

  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
`;

// Individual card styling for product placeholders
const PlaceholderCard = styled.article`
  position: relative;
  flex: 0 0 auto;
  height: 45vh;
  width: 37vh;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
`;

// Image placeholder styling with img tag
const PlaceholderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

// Link styling for product card text
const PlaceholderText = styled(Link)`
  font-size: 14px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 7px;
  z-index: 2;
  color: black;
  background-color: white;
  opacity: 0.7;
  text-decoration: none;
  &:hover {
    opacity: 0.9;
  }
`;

// Styling for the product card header text
const PlaceholderTextUp = styled.h2`
  font-size: 50px;
  font-weight: 650;
  color: white;
  z-index: 2;
  margin-bottom: 100px;
`;

// Overlay styling for product card
const CardOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  z-index: 1;
`;

// HomePage component
const HomePage = () => {
  const scrollRef = useRef();

  // Handle scroll event logic if needed
  const handleScroll = () => {
    if (scrollRef.current) {
      const {scrollLeft, scrollWidth, clientWidth} = scrollRef.current;
      // Handle scrolling logic if needed
    }
  };

  // Effect to handle scroll events
  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <MainContainer>
      <Helmet>
        <title>Grus och Jord för Trädgård och Bygge | Din Svenska Leverantör</title>
        <meta
          name="description"
          content="Köp högkvalitativ grus, jord och tillbehör till konkurrenskraftiga priser. Guider för grusuppfart, husgrund, dränering och mer."
        />
        <meta
          name="keywords"
          content="grus, jord, grusuppfart, husgrund, dränering, trädgård, bygge, Sverige"
        />
        <link rel="canonical" href="https://www.nordicsoil.se/" />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            'name': 'Din Leverantör för Grus & Jord',
            'url': 'https://www.nordicsoil.se',
            'potentialAction': {
              '@type': 'SearchAction',
              'target': 'https://www.nordicsoil.se/sok?q={search_term_string}',
              'query-input': 'required name=search_term_string',
            },
          })}
        </script>
      </Helmet>
      <FirstContainer>
        <FirstImage
          src={`/assets/villa_grus.jpg`}
          alt="Villa med grusuppfart"
          loading="eager"
        />
        <Overlay />
      </FirstContainer>
      <LogoContainer>
        <Logo src={logo} alt="Företagslogotyp" />
        <LogoText>Jord och grus, helt enkelt</LogoText>
        <nav>
          <AboutUs to="/om-oss">Om Oss</AboutUs>
        </nav>
      </LogoContainer>
      <SecondContainer>
        <SecondImage
          src={`/assets/grid_image3.jpg`}
          alt="Trädgårdsarbete med grus"
          loading="lazy"
        />
        <Overlay />
      </SecondContainer>
      <ScrollableProducts>

        <RecommendedContainer>
          <ScrollableArea ref={scrollRef} onScroll={handleScroll} aria-label="Produktkategorier">
            <PlaceholderCard>
              <PlaceholderImage
                src={`/assets/jord.jpg`}
                alt="Högkvalitativ jord för trädgård"
                loading="lazy"
              />
              <CardOverlay>
                <PlaceholderTextUp>Jord</PlaceholderTextUp>
                <PlaceholderText to="/produkter" state={{category: 'Jord'}}>Shoppa nu</PlaceholderText>
              </CardOverlay>
            </PlaceholderCard>
            <PlaceholderCard>
              <PlaceholderImage
                src={`/assets/grus.jpg`}
                alt="Olika typer av grus för bygge och trädgård"
                loading="lazy"
              />
              <CardOverlay>
                <PlaceholderTextUp>Sand & grus</PlaceholderTextUp>
                <PlaceholderText to="/produkter" state={{category: 'Sand & grus'}}>Shoppa nu</PlaceholderText>
              </CardOverlay>
            </PlaceholderCard>
          </ScrollableArea>
        </RecommendedContainer>
      </ScrollableProducts>
      <ThirdContainer>
        <ThirdImage
          src={`/assets/grid_image2.jpg`}
          alt="Trädgårdsdesign med grus och jord"
          loading="lazy"
        />
        <Overlay />
      </ThirdContainer>
    </MainContainer>
  );
};

export default HomePage;
