import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Header from './Header';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import remarkGfm from 'remark-gfm';

const GuideContainer = styled.div`
  margin: 0 auto;
  margin-top: 80px;
  padding: 20px;
  max-width: 800px;
`;

const SpecialContainer = styled.div`
  padding: 20px;
  background-color: rgba(0, 123, 255, 0.1); /* Light blue with transparency */
  border-left: 5px solid #007bff;
  border-radius: 5px;
  margin: 20px 0;
  color: #333;
`;

const StyledH1 = styled.h1`
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
`;

const StyledH2 = styled.h2`
  font-size: 24px;
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const StyledParagraph = styled.p`
  font-size: 16px;
  color: #666;
  margin-top: 10px;
`;

const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  color: #333;
`;

const StyledListItem = styled.li`
  margin-bottom: 10px;
`;

const StyledBold = styled.strong`
  font-weight: bold;
`;

const Blockquote = ({ children }) => {
  if (
    children &&
    Array.isArray(children) &&
    typeof children[0] === 'string' &&
    children[0].startsWith('[!IMPORTANT]')
  ) {
    const text = children[0].replace('[!IMPORTANT]', '').trim();
    return <SpecialContainer>{text}</SpecialContainer>;
  }
  return <blockquote>{children}</blockquote>;
};

const components = {
  h1: ({ children }) => <StyledH1>{children}</StyledH1>,
  h2: ({ children }) => <StyledH2>{children}</StyledH2>,
  p: ({ children }) => <StyledParagraph>{children}</StyledParagraph>,
  ul: ({ children }) => <StyledList>{children}</StyledList>,
  li: ({ children }) => <StyledListItem>{children}</StyledListItem>,
  strong: ({ children }) => <StyledBold>{children}</StyledBold>,
  blockquote: Blockquote,
};

const GuideTemplate = () => {
  const [content, setContent] = useState('');
  const { guideId } = useParams();

  const markdownFile = `/guides/${guideId}.md`;

  const guideTitle = guideId
    .replace(/-/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

  useEffect(() => {
    fetch(markdownFile)
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, [markdownFile]);

  return (
    <>
    <Header forceWhiteHeader = {true}/>
    <GuideContainer>
      <Helmet>
        <title>{`Guide - ${guideTitle}`}</title>
        <meta
          name="description"
          content="En guide för att hjälpa dig med ditt projekt."
        />
      </Helmet>
      <ReactMarkdown components={components} remarkPlugins={[remarkGfm]}>
        {content}
      </ReactMarkdown>
    </GuideContainer>
    </>
  );
};

export default GuideTemplate;
