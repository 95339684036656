import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './Header';
import { usePostcode } from '../context/PostcodeContext';
import { useCart } from '../context/CartContext';
import CartModal from './CartModal';
import PostcodeModal from './PostcodeModal';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { mockProductLocations } from './mockProducts/mockProductLocations';
import { mockProducts } from './mockProducts/mockProducts';


const NoProductContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const NoProductText = styled.h1`
  color: black;
  text-align: center;
  font-size: 32px; /* Adjust font size as needed */
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const NoProductSubText = styled.p`
  color: black;
  text-align: center;
  font-size: 20px; /* Adjust font size as needed */
  text-transform: lowercase;
`;

const Container = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 20px;
`;

const Banner = styled.div`
  width: 100%;
  height: 110px;
  background: url(/assets/big_garden.jpg) no-repeat center center/cover;
  position: relative;
  margin-bottom: 20px;
`;

const BannerOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
  text-decoration: underline;
  margin-bottom: 20px;

  &:hover {
    color: #555;
  }
`;

const ArrowIcon = styled(ArrowBackIosNewOutlinedIcon)`
  margin-right: 10px;
`;

const ProductImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 600px;
  margin-bottom: 20px;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductName = styled.h1`
  font-size: 24px;
  color: #333;
`;

const PriceChangeAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const ProductPrice = styled.p`
  font-size: 20px;
  color: #555;
  animation: ${(props) => (props.animate ? PriceChangeAnimation : 'none')} 0.5s ease-in-out;
`;

const ProductDescriptionContainer = styled.p`
  font-size: 16px;
  color: #666;
  margin-top: 10px;
`;

const PostcodeInfo = styled.p`
  font-size: 16px;
  color: black;
  margin-top: 10px;
`;

const PostcodeLink = styled.span`
  color: black;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: darkblue;
  }
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const QuantityInput = styled.input`
  width: 60px;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const AddToCartButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: #555;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  color: #333;
  margin-top: 30px;
`;

const UsageContainer = styled.div`
  margin-top: 5px;
`;

const UsageList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  color: #333;
`;

const UsageItem = styled.li`
  margin-bottom: 10px;
`;

const InspirationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const InspirationBox = styled.div`
  width: calc(33.33% - 10px);
  text-align: center;
`;

const InspirationImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
`;

const SizeSelect = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  appearance: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none; /* Firefox */

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #555;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;

const DropdownArrow = styled.span`
  position: absolute;
  top: 50%;
  right: 15px;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  pointer-events: none;
  transform: translateY(-50%);
`;

const NoLocationsMessage = styled.p`
  font-size: 16px;
  color: #333;
  margin-top: 10px;
`;

const StyledH2 = styled.h2`
  font-size: 20px;
  color: #333;
  margin-top: 30px;
`;

const StyledH3 = styled.h3`
  font-size: 18px;
  color: #555;
  margin-top: 20px;
`;

const StyledParagraph = styled.p`
  font-size: 16px;
  color: #666;
  margin-top: 10px;
`;

const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  color: #333;
`;

const StyledListItem = styled.li`
  margin-bottom: 10px;
`;

const StyledBold = styled.strong`
  font-weight: bold;
`;


const parseTextToJSX = (text) => {
  if (!text) {
    text = 'Otroligt bra beskrivning ska finnas här, vi lovar att den är på väg...';
  }

  const lines = text.split('\n');
  const elements = [];
  let currentListItems = [];

  lines.forEach((line, index) => {
    line = line.trim();

    if (line.startsWith('*h2 ')) {
      if (currentListItems.length > 0) {
        elements.push(<StyledList key={`list-${index}`}>{currentListItems}</StyledList>);
        currentListItems = [];
      }
      elements.push(<StyledH2 key={`h2-${index}`}>{line.slice(4)}</StyledH2>);
    } else if (line.startsWith('**h3 ')) {
      if (currentListItems.length > 0) {
        elements.push(<StyledList key={`list-${index}`}>{currentListItems}</StyledList>);
        currentListItems = [];
      }
      elements.push(<StyledH3 key={`h3-${index}`}>{line.slice(5)}</StyledH3>);
    } else if (line.startsWith('- ')) {
      currentListItems.push(<StyledListItem key={`list-item-${index}`}>{line.slice(2)}</StyledListItem>);
    } else if (line.length > 0) {
      const formattedLine = line.split(/\*\*(.*?)\*\*/g).map((part, i) =>
        i % 2 === 1 ? <StyledBold key={i}>{part}</StyledBold> : part,
      );

      if (currentListItems.length > 0) {
        elements.push(<StyledList key={`list-${index}`}>{currentListItems}</StyledList>);
        currentListItems = [];
      }

      elements.push(<StyledParagraph key={`paragraph-${index}`}>{formattedLine}</StyledParagraph>);
    }
  });

  if (currentListItems.length > 0) {
    elements.push(<StyledList key="final-list">{currentListItems}</StyledList>);
  }

  return elements;
};

const ProductDescription = ({ description }) => {
  return <div>{parseTextToJSX(description)}</div>;
};

const ProductDetail = () => {
  const { product_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const { postcode } = usePostcode();
  const { addToCart } = useCart();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPostcodeModalOpen, setIsPostcodeModalOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedPriceType, setSelectedPriceType] = useState(null); 
  const [animatePrice, setAnimatePrice] = useState(false);

  const handleAddToCart = () => {
    if (quantity < 1 || quantity > 1000) {
      setError('Ange ett korrekt mängd (1-1000 ton)');
    } else {
      setError('');
      const selectedLocation = filteredLocations.find((location) => location.size === selectedSize);
      if (selectedLocation) {
        addToCart(
          {
            ...product,
            size: selectedSize,
            price: selectedLocation.price, // Ensure price from product_location is passed
            price_type: selectedLocation.price_type,
          },
          quantity,
          selectedLocation.price,  // Pass the price from product_location
          selectedSize
        );
        setIsModalOpen(true);
      }
    }
  };
  

  const handleSizeChange = (e) => {
    const size = e.target.value;
    setSelectedSize(size);

    const selectedLocation = filteredLocations.find((location) => location.size === size);
    if (selectedLocation) {
      setSelectedPrice(selectedLocation.price);
      setSelectedPriceType(selectedLocation.price_type);
      setAnimatePrice(true);
      setTimeout(() => setAnimatePrice(false), 1000);
    }
  };

  useEffect(() => {
    if (locations.length > 0 && postcode && postcode.trim() !== '') {
      const postcodePrefix = postcode.substring(0, 2);
      const filtered = locations.filter((location) => {
        const postalCodes = location.available_postal_codes.split(',');
        return postalCodes.some((code) => code.startsWith(postcodePrefix));
      });
      setFilteredLocations(filtered);

      if (filtered.length > 0) {
        const cheapestLocation = filtered.reduce((min, loc) =>
          loc.price < min.price ? loc : min
        );
        setSelectedPrice(cheapestLocation.price);
        setSelectedPriceType(cheapestLocation.price_type);
      }
    } else {
      setFilteredLocations([]);
    }
  }, [locations, postcode]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        setIsLoading(true); // Start loading
        let productData;
        let locationsData;
  

          // Fetch real products and locations data in production
          const productResponse = await fetch(`${process.env.REACT_APP_API_URL}/users/products/${product_id}`);
          if (!productResponse.ok) {
            throw new Error('Failed to fetch product data');
          }
          productData = await productResponse.json();
  
          const locationsResponse = await fetch(`${process.env.REACT_APP_API_URL}/productlocations/${product_id}/locations`);
          if (!locationsResponse.ok) {
            throw new Error('Failed to fetch product locations');
          }
          locationsData = await locationsResponse.json();
        
  
        const imageUrl = productData.product_img
          ? `/assets/product_images/${productData.product_img}`
          : 'https://via.placeholder.com/600?text=No+Image+Available';
  
        setProduct({
          id: product_id,
          name: productData.product_name,
          price: productData.product_price,
          description: productData.product_description || 'Otroligt bra beskrivning ska finnas här, vi lovar att den är på väg...',
          imageUrl,
          price_type: productData.price_type,
        });
  
        setLocations(locationsData);
        setIsLoading(false); // End loading
      } catch (error) {
        console.error('Error fetching product details:', error);
        setIsLoading(false); // End loading on error
      }
    };
    fetchProductData();
  }, [product_id]);

  if (isLoading) {
    return (
      <>
        <Header forceWhiteHeader={true} />
        <NoProductContainer>
          <NoProductText>Laddar...</NoProductText>
        </NoProductContainer>
      </>
    );
  }
  
  if (!product) {
    return (
      <>

        <Helmet>
          <title>Sidan Hittades Inte - Jord och Grus</title>
          <meta name="description" content="Sidan du letar efter är för närvarande inte tillgänglig." />
        </Helmet>
        <NoProductContainer>
          <NoProductText>SIDAN HITTADES INTE</NoProductText>
          <NoProductSubText>Sidan du letar efter är för närvarande inte tillgänglig.</NoProductSubText>
        </NoProductContainer>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{product.name} - Nordic soil</title>
        <meta name="description" content={`${product.name} - Nordic soil`} />
      </Helmet>
      <Banner>
        <BannerOverlay>
        </BannerOverlay>
      </Banner>
      <Container>
        <BackLink to="/produkter">
          <ArrowIcon />
          Tillbaka till alla produkter
        </BackLink>
        <ProductImage
          src={imageError ? 'https://via.placeholder.com/600?text=No+Image+Available' : product.imageUrl}
          alt={product.name}
          onError={() => setImageError(true)}
        />
        <ProductInfo>
          <ProductName>{product.name}</ProductName>
          {(!postcode.trim() || postcode === 'Ange postnummer') ? (
            <>
              <ProductPrice animate={animatePrice}>
                Från {product.price} kr / {product.price_type}
              </ProductPrice>
              <PostcodeInfo>
                Se vad det kostar i ditt område,{' '}
                <PostcodeLink onClick={() => setIsPostcodeModalOpen(true)}>ange postnummer här</PostcodeLink>
              </PostcodeInfo>
            </>
          ) : filteredLocations.length > 0 ? (
            <>
              <ProductPrice animate={animatePrice}>
                {selectedPrice !== null ? selectedPrice : product.price} kr / {selectedPriceType !== null ? selectedPriceType : product.price_type}
              </ProductPrice>
              <PostcodeInfo>
                Priset beräknas utifrån postnumret <strong>{postcode}</strong>
              </PostcodeInfo>
              <SectionTitle>Välj storlek</SectionTitle>
              <SelectWrapper>
                <SizeSelect
                  value={selectedSize}
                  onChange={handleSizeChange}
                >
                  <option value="">Välj storlek</option>
                  {filteredLocations.map((location, index) => (
                    <option key={index} value={location.size}>
                      {location.size}
                    </option>
                  ))}
                </SizeSelect>
                <DropdownArrow />
              </SelectWrapper>
              <QuantityContainer>
                <QuantityInput
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(parseInt(e.target.value))}
                  min="1"
                  max="1000"
                  placeholder="ton"
                />
                <AddToCartButton onClick={handleAddToCart}>Lägg till i varukorg</AddToCartButton>
              </QuantityContainer>
            </>
          ) : (
            <NoLocationsMessage>
              Vi har tyvärr för närvarande inte den här produkten inom ditt angivna område, men kontakta oss på <a href="mailto:jonahwk@nordicsoil.se">jonahwk@nordicsoil.se</a> så kan vi hjälpa dig att hitta ditt perfekta material.
            </NoLocationsMessage>
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ProductDescriptionContainer>
            <ProductDescription description={product.description} />
          </ProductDescriptionContainer>
          <SectionTitle>Inspiration</SectionTitle>
          <InspirationContainer>
            <InspirationBox>
              <InspirationImage src="https://via.placeholder.com/150" alt="Inspiration 1" />
            </InspirationBox>
            <InspirationBox>
              <InspirationImage src="https://via.placeholder.com/150" alt="Inspiration 2" />
            </InspirationBox>
            <InspirationBox>
              <InspirationImage src="https://via.placeholder.com/150" alt="Inspiration 3" />
            </InspirationBox>
          </InspirationContainer>
        </ProductInfo>
        <CartModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} product={product} />
        <PostcodeModal isOpen={isPostcodeModalOpen} onClose={() => setIsPostcodeModalOpen(false)} />
      </Container>
    </>
  );
};

export default ProductDetail;
