// view/src/components/MenuContainer.js
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { menuItems } from './menuItems'; // Import the list of menu items

const MenuContainerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 75%;
  max-width: 300px;
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: left 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
`;

const CloseButton = styled(CloseIcon)`
  align-self: flex-end;
  cursor: pointer;
`;

const MenuItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
  width: 100%;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const MenuText = styled.span`
  font-size: 18px;
  color: black;
`;

const MenuArrow = styled.span`
  font-size: 18px;
  color: black;

  @media (min-width: 768px) {
    display: none; // Hide the arrow on larger screens
  }
`;

const MenuContainer = ({ isOpen, toggleMenu }) => {
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      toggleMenu();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.style.overflow = '';
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <MenuContainerWrapper isOpen={isOpen} ref={menuRef}>
      <CloseButton onClick={toggleMenu} />
      {/* Generate menu items based on the list */}
      {menuItems.map((item) => (
        <MenuItem to={item.link} key={item.text} onClick={toggleMenu}>
          <MenuText>{item.text}</MenuText>
          <MenuArrow>&gt;</MenuArrow> {/* Only visible on mobile */}
        </MenuItem>
      ))}
    </MenuContainerWrapper>
  );
};

export default MenuContainer;
