// src/context/PostcodeContext.js

import React, {createContext, useState, useContext, useEffect} from 'react';

const PostcodeContext = createContext();

export const usePostcode = () => useContext(PostcodeContext);

export const PostcodeProvider = ({children}) => {
  const [postcode, setPostcode] = useState(() => {
    // Load postcode from localStorage if available
    return localStorage.getItem('postcode') || '';
  });

  // Persist postcode changes to localStorage whenever the postcode state changes
  useEffect(() => {
    localStorage.setItem('postcode', postcode);
  }, [postcode]);

  return (
    <PostcodeContext.Provider value={{postcode, setPostcode}}>
      {children}
    </PostcodeContext.Provider>
  );
};
