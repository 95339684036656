//sds-ecom/view/src/components/Products.js

import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { usePostcode } from '../context/PostcodeContext';
import { mockProducts } from './mockProducts/mockProducts';

const zoomIn = keyframes`
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const MainContainer = styled.main`
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
`;

const Container = styled.div`
  margin: 15px;
  padding-top: 20px;
`;

const FirstContainer = styled.section`
  width: 100%;
  height: 45vh;
  background: url(/assets/grusgang_trad.jpg) no-repeat center center/cover;
  position: relative;
  animation: ${zoomIn} 1.5s ease forwards;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const FirstText = styled.h1`
  font-size: 36px;
  color: white;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const FirstArticle = styled.article`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(/assets/borr_och_ritning.jpg) no-repeat center center/cover;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
`;

const FirstArticleOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const FirstArticleText = styled.h2`
  font-size: 24px;
  color: white;
  text-align: center;
  margin: 0;
  z-index: 2;
`;

const SEOImage = styled.img`
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
`;

const CategorySelect = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
`;

const ProductsCount = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
  
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background: #ccc;
    margin-left: 10px;
  }
`;

const ProductsGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ProductCard = styled.article`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:focus-within {
    outline: 2px solid #333;
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
`;

const ProductDetails = styled.div`
  margin-top: 10px;
  padding: 0 10px;
`;

const ProductNameAndPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
`;

const ProductName = styled.h3`
  font-size: 16px;
  color: #333;
  margin: 0;
  text-align: left;
`;

const ProductPrice = styled.p`
  font-size: 14px;
  color: #555;
  margin: 0;
  text-align: right;
`;

const ProductShortDescription = styled.p`
  font-size: 12px;
  color: #777;
  margin-top: 5px;
  text-align: left;
`;

const Products = () => {
  const { postcode } = usePostcode();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const placeholderImage = `/assets/placeholder.jpg`;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let data;

          const response = await fetch(`${process.env.REACT_APP_API_URL}/users/products`);
          data = await response.json();
        
        
        const inAssortmentProducts = data.filter(product => product.in_assortment);
        setProducts(inAssortmentProducts);
        const uniqueCategories = [...new Set(inAssortmentProducts.map(product => product.category).filter(Boolean))];
        setCategories(uniqueCategories);

        if (postcode && postcode !== 'Ange postnummer' && postcode.length >= 2) {
          filterProductsByPostcode(inAssortmentProducts, postcode);
        } else {
          // If no postcode is entered, find and set the lowest price across all locations
          const productsWithLowestPrices = inAssortmentProducts.map(product => {
            if (Array.isArray(product.product_locations_prices)) {
              const lowestPriceLocation = product.product_locations_prices.reduce((min, loc) =>
                loc.price < min.price ? loc : min
              );

              return {
                ...product,
                product_price: lowestPriceLocation.price,
                price_type: lowestPriceLocation.price_type,
              };
            }
            return product;
          });

          setFilteredProducts(productsWithLowestPrices);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [postcode]);

  const filterProductsByPostcode = (products, postcode) => {
    const postcodePrefix = postcode.substring(0, 2);
    const filtered = products
      .map(product => {
        if (Array.isArray(product.product_locations_prices)) {
          const availableLocations = product.product_locations_prices.filter(location =>
            location.available_postal_codes.split(',').some(code => code.startsWith(postcodePrefix))
          );

          if (availableLocations.length > 0) {
            const lowestPriceLocation = availableLocations.reduce((min, loc) =>
              loc.price < min.price ? loc : min
            );

            return {
              ...product,
              product_price: lowestPriceLocation.price,
              price_type: lowestPriceLocation.price_type,
            };
          }
        }
        return null;
      })
      .filter(product => product !== null);

    setFilteredProducts(filtered);
  };

  useEffect(() => {
    if (selectedCategory) {
      const filtered = products.filter(product => {
        return product.category && product.category.includes(selectedCategory);
      });
      setFilteredProducts(filtered);
    } else if (!postcode || postcode === 'Ange postnummer' || postcode.length < 2) {
      const productsWithLowestPrices = products.map(product => {
        if (Array.isArray(product.product_locations_prices)) {
          const lowestPriceLocation = product.product_locations_prices.reduce((min, loc) =>
            loc.price < min.price ? loc : min
          );

          return {
            ...product,
            product_price: lowestPriceLocation.price,
            price_type: lowestPriceLocation.price_type,
          };
        }
        return product;
      });
      setFilteredProducts(productsWithLowestPrices);
    }
  }, [selectedCategory, products, postcode]);

  const handleProductClick = productCode => {
    navigate(`/produkter/${productCode}`);
  };

  const handleCategoryChange = event => {
    const category = event.target.value;
    setSelectedCategory(category);

    if (category === '') {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(product =>
        product.category.includes(category)
      );
      setFilteredProducts(filtered);
    }
  };

  const handleFirstArticleClick = () => {
    navigate('/guider');
  };

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: filteredProducts.map((product, index) => ({
      "@type": "Product",
      position: index + 1,
      name: product.product_name,
      image: product.product_img || placeholderImage,
      description: product.product_description,
      offers: {
        "@type": "Offer",
        priceCurrency: "SEK",
        price: product.product_price,
        availability: "https://schema.org/InStock",
      },
    })),
  };

  return (
    <MainContainer>
      <Helmet>
        <title>Våra Produkter - Jord och Grus</title>
        <meta
          name="description"
          content="Utforska vårt breda sortiment av jord och grus. Högkvalitativa produkter för alla dina trädgårds- och byggprojekt."
        />
        <meta
          name="keywords"
          content="jord, grus, trädgård, bygge, produkter, köp online"
        />
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>
      <FirstContainer>
        <Overlay />
        <FirstText>Produkter</FirstText>
      </FirstContainer>
      <Container>
        <FirstArticle onClick={handleFirstArticleClick}>
          <FirstArticleOverlay />
          <FirstArticleText>Du har väl inte missat våra guider?</FirstArticleText>
          <SEOImage src={`/assets/borr_och_ritning.jpg`} alt="Borr och ritning för byggprojekt" />
        </FirstArticle>
        {categories.length > 0 && (
          <CategorySelect
            value={selectedCategory}
            onChange={handleCategoryChange}
            aria-label="Select Product Category"
          >
            <option value="">Välj kategori</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </CategorySelect>
        )}
        <ProductsCount>
          Visar {filteredProducts.length} {filteredProducts.length === 1 ? 'produkt' : 'produkter'}
        </ProductsCount>
        <ProductsGrid aria-label="Product Grid">
          {filteredProducts.map((product, index) => (
            <ProductCard
              key={product.product_id}
              onClick={() => handleProductClick(product.product_id)}
              tabIndex="0"
              aria-labelledby={`product-${index}`}
            >
              <ProductImage
                src={product.product_img ? `/assets/product_images/${product.product_img}` : placeholderImage}
                alt={product.product_name}
              />
              <ProductDetails>
                <ProductNameAndPrice>
                  <ProductName id={`product-${index}`}>
                    {product.product_name}
                  </ProductName>
                  <ProductPrice>
                    {`Från ${product.product_price} kr / ${product.price_type}`}
                  </ProductPrice>
                </ProductNameAndPrice>
                <ProductShortDescription>
                  {product.short_description}
                </ProductShortDescription>
              </ProductDetails>
            </ProductCard>
          ))}
        </ProductsGrid>
      </Container>
    </MainContainer>
  );
};

export default Products;
