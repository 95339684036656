//view/src/context/CartContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    // Load cart from localStorage on initial load
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Function to add items to the cart
  const addToCart = (product, quantity, locationPrice, size) => {
    setCart((prevCart) => {
      // Ensure the product has a valid ID
      if (!product.id) {
        console.error("Product ID is undefined:", product);
        return prevCart;
      }
  
      // Check if the product with the same size is already in the cart
      const existingProductIndex = prevCart.findIndex(
        (item) => item.product.id === product.id && item.size === size
      );
  
      if (existingProductIndex >= 0) {
        // If the product is already in the cart, update its quantity
        const updatedCart = [...prevCart];
        updatedCart[existingProductIndex].quantity += quantity;
        return updatedCart;
      } else {
        // If the product is not in the cart, add it with the location price and size
        const newCart = [...prevCart, { product, quantity, price: locationPrice, size }];
        return newCart;
      }
    });
  };

  // Function to update the quantity of a specific item in the cart
  const updateCartItemQuantity = (productId, size, quantity) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.product.id === productId && item.product.size === size
          ? { ...item, quantity }
          : item
      )
    );
  };

  // Function to remove an item from the cart
  const removeFromCart = (productId, size) => {
    setCart((prevCart) =>
      prevCart.filter(
        (item) => !(item.product.id === productId && item.product.size === size)
      )
    );
  };

   // Persist cart changes to localStorage whenever the cart state changes
   useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

const findClosestSuppliers = async (customerAddress) => {
    try {
      const response = await axios.post('/api/closest-supplier', {
        customerAddress,
        cart,
      });
      return response.data; // Returns closest suppliers with distances
    } catch (error) {
      console.error('Error finding closest suppliers:', error);
      return null;
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        addToCart,
        updateCartItemQuantity,
        removeFromCart,
        findClosestSuppliers,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
