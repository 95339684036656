// mockProductLocations.js

export const mockProductLocations = [
    {
      product_id: 1,
      size: "0-16mm",
      price: "120",
      price_type: "ton",
      available_postal_codes: "11,12,13"
    },
    {
      product_id: 1,
      size: "16-32mm",
      price: "130",
      price_type: "ton",
      available_postal_codes: "11,12"
    },
    {
      product_id: 2,
      size: "0-2mm",
      price: "210",
      price_type: "ton",
      available_postal_codes: "11,21,22"
    },
    {
      product_id: 2,
      size: "0-4mm",
      price: "220",
      price_type: "ton",
      available_postal_codes: "11,21,22,23"
    },
    {
      product_id: 3,
      size: "32-63mm",
      price: "150",
      price_type: "skratt",
      available_postal_codes: "11,21,22"
    },
    // Add more mock product locations as needed
  ];
  