// src/components/AboutUs.js

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg';

// Main container for the About Us page
const MainContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto; // Allow vertical scrolling
`;

// Styled component for the header section
const HeaderContainer = styled.div`
  width: 100%;
  height: 50vh;
  background-image: url(/assets/about_us_header.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
`;

// Overlay to darken the background image
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

// Content container for text and images
const ContentContainer = styled.div`
  padding: 40px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
`;

// Styled component for the section title
const SectionTitle = styled.h2`
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

// Styled component for paragraph text
const Paragraph = styled.p`
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555;
  max-width: 800px;
  text-align: justify;
`;

// Styled component for team section
const TeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

// Individual team member card
const TeamMember = styled.div`
  width: 250px;
  text-align: center;
  background-color: #f7f7f7;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Image for team member
const MemberImage = styled.img`
  width: 100%;
  height: auto;
`;

// Name of the team member
const MemberName = styled.h3`
  font-size: 20px;
  margin: 15px 0 5px 0;
  color: #333;
`;

// Position of the team member
const MemberPosition = styled.p`
  font-size: 16px;
  color: #777;
  margin-bottom: 15px;
`;

// AboutUs component
const AboutUs = () => {
  return (
    <MainContainer>
      <Helmet>
        <title>Om Oss - Din Svenska Leverantör för Grus och Jord</title>
        <meta
          name="description"
          content="Lär dig mer om vårt företag, vårt team, och våra värderingar. Vi strävar efter att leverera högkvalitativ grus och jord för dina bygg- och trädgårdsprojekt."
        />
      </Helmet>
      <HeaderContainer>
        <Overlay />
      </HeaderContainer>
      <ContentContainer>
        <SectionTitle>Om Oss</SectionTitle>
        <Paragraph>
          Vi är ett svenskt företag som specialiserar oss på att leverera grus, jord och andra material för trädgårds- och byggprojekt. Med många års erfarenhet i branschen är vårt mål att erbjuda högkvalitativa produkter och enastående kundservice.
        </Paragraph>
        <Paragraph>
          Vårt team består av passionerade experter som arbetar hårt för att säkerställa att du får det bästa materialet för dina behov. Vi tror på hållbarhet, kvalitet och kundnöjdhet, och vi strävar alltid efter att överträffa dina förväntningar.
        </Paragraph>
        <SectionTitle>Vårt Team</SectionTitle>
        <TeamContainer>
          <TeamMember>
            <MemberImage src={`/assets/team_member_1.jpg`} alt="Medarbetare 1" />
            <MemberName>Anna Svensson</MemberName>
            <MemberPosition>VD & Grundare</MemberPosition>
          </TeamMember>
          <TeamMember>
            <MemberImage src={`/assets/team_member_2.jpg`} alt="Medarbetare 2" />
            <MemberName>Erik Johansson</MemberName>
            <MemberPosition>Produktchef</MemberPosition>
          </TeamMember>
          <TeamMember>
            <MemberImage src={`/assets/team_member_3.jpg`} alt="Medarbetare 3" />
            <MemberName>Sofia Nilsson</MemberName>
            <MemberPosition>Marknadsföringschef</MemberPosition>
          </TeamMember>
        </TeamContainer>
      </ContentContainer>
    </MainContainer>
  );
};

export default AboutUs;
