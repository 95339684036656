// src/components/CartModal.js

import React, {useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOutToRight = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

const MessageContainer = styled.div`
  position: fixed;
  top: 70px; /* Adjust this based on your header height */
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 500px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  animation: ${(props) => (props.isClosing ? slideOutToRight : slideInFromLeft)} 0.5s ease-out forwards;
  z-index: 1100;
`;

const MessageText = styled.p`
  font-size: 16px;
  margin: 0;
`;

const CartModal = ({isOpen, onClose}) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setIsClosing(true);
        setTimeout(() => {
          onClose();
          setIsClosing(false);
        }, 500); // Duration of slide-out animation
      }, 1000); // Message visible duration
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  if (!isOpen && !isClosing) return null;

  return (
    <MessageContainer isClosing={isClosing}>
      <MessageText>Tillagd i varukorgen</MessageText>
    </MessageContainer>
  );
};

export default CartModal;
