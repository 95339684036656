// src/components/ArticlePage.js

import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const ArticlePageContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BannerContainer = styled.div`
  width: 100%;
  height: 250px;
  background: url(/assets/planera_projektritnig.jpg) no-repeat center center/cover;
  position: relative;
  margin-bottom: 20px;
`;

const BannerOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BannerText = styled.h1`
  font-size: 36px;
  color: white;
  text-align: center;
  margin-top: 170px;
`;

const ArticleContainer = styled.div`
  position: relative;
  width: 80%;
  height: 300px;
  margin: 20px 0;
  border-radius: 10px;
  overflow: hidden;
  background: url(/assets/grusgang_trad.jpg) no-repeat center center/cover;
`;

const ArticleImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
`;

const ArticleContent = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
`;

const ArticleHeader = styled.h2`
  margin: 0;
  font-size: 24px;
  color: black;
`;

const ReadButton = styled(Link)`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  &:hover {
    background-color: #0056b3;
  }
`;

const articles = [
  {title: 'Dränera Husgrund', imageUrl: `/assets/article1.jpg`, link: 'dranera-husgrund'},
  {title: 'Planera Projekt', imageUrl: `/assets/article2.jpg`, link: 'planera-projekt'},
  {title: 'Välj Rätt Material', imageUrl: `/assets/article3.jpg`, link: 'valj-ratt-material'},
];

const ArticlePage = () => {
  return (
    <ArticlePageContainer>
      <BannerContainer>
        <BannerOverlay>
          <BannerText>Guider för att planera ditt projekt</BannerText>
        </BannerOverlay>
      </BannerContainer>
      {articles.map((article, index) => (
        <ArticleContainer key={index}>
          <ArticleImage style={{backgroundImage: `url(${article.imageUrl})`}} />
          <ArticleContent>
            <ArticleHeader>{article.title}</ArticleHeader>
            <ReadButton to={`/guider/${article.link}`}>Läs guiden</ReadButton>
          </ArticleContent>
        </ArticleContainer>
      ))}
    </ArticlePageContainer>
  );
};

export default ArticlePage;
