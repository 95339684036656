// src/components/DevelopmentModal.js

import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.svg';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 150vh;
  height: 80vh;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 150px;  // Adjust the size as needed
  height: auto;
  margin-bottom: 20px;
`;

const CloseIcon = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  font-size: 24px;
  color: white;

  &:hover {
    color: white;
    cursor: default;
  }
`;

const DevelopmentModal = ({onClose}) => (
  <ModalBackground>
    <ModalContent>

      <h1>Hemsida under utveckling</h1>
      <Image src={logo} alt="Construction Worker" />  {/* Add the SVG image */}
      <p>Nordic Soils hemsida är för nuvarande under utveckling, men snart är vi i gång...
      </p>
      <p>Hör gärna av er till <strong>jonahwk@nordicsoil.se</strong> vid frågor om vårt erbjudande eller om ni helt enkelt vill diskutera lite jord och grus!</p>
      <CloseIcon onClick={onClose}>&times;</CloseIcon>
    </ModalContent>
  </ModalBackground>
);

export default DevelopmentModal;
