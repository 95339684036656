import React, {useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import {usePostcode} from '../context/PostcodeContext'; // Correct path

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align to the bottom */
  background-color: rgba(0, 0, 0, 0.7); /* High opacity black background at the top */
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  width: 100%;
  max-width: 500px;
  height: 65%; /* Cover 90% of the screen height */
  text-align: center;
  animation: ${(props) => (props.isClosing ? slideDown : slideUp)} 0.3s ease-out;
  display: flex;
  flex-direction: column; /* Ensure the content is in a column layout */
  justify-content: space-between; /* Space the content out evenly */
  position: relative;
`;

const CloseButton = styled.button`
  color: black;  
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 35px;
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  width: calc(100% - 22px);  
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  padding: 0 10px;
  text-align: justify;
`;

const Input = styled.input`
  padding: 10px;
  margin: 20px 0;
  width: calc(100% - 47px);
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40%;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 10px; /* Adjust margin to stack buttons properly */
  cursor: pointer;
  font-size: 20px; /* Adjust font size to match the rest of the modal */
  border: none;
  border-radius: 7px;
  background-color: black;
  color: white;
  width: 80%; /* Adjust the width to fit the container */
  height: 6vh;

  &:hover {
    background-color: #555;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const PostcodeModal = ({isOpen, onClose}) => {
  const {postcode, setPostcode} = usePostcode();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const [isClosing, setIsClosing] = useState(false);

  const handleSubmit = () => {
    if (inputValue.length === 5 && /^\d+$/.test(inputValue)) {
      setPostcode(inputValue);
      setError('');
      closeModal();
    } else {
      setError('Ange ett korrekt postnummer');
    }
  };

  const handleForget = () => {
    setPostcode('Ange postnummer');
    setInputValue('');
    closeModal();
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  useEffect(() => {
    if (isOpen) {
      if (postcode && postcode !== 'Ange postnummer') {
        setInputValue(postcode); // Show existing postcode
      } else {
        setInputValue(''); // Clear input if no valid postcode exists
      }
    }
  }, [isOpen, postcode]);

  if (!isOpen && !isClosing) return null;

  return (
    <ModalOverlay>
      <ModalContent isClosing={isClosing}>
        <CloseButton onClick={closeModal}>×</CloseButton>
        <div>
          <ModalTitle>Ange postnummer</ModalTitle>
          <ModalText>
            Genom att ange ditt postnummer kan vi erbjuda dig exakt pris och leveranskostnad baserat på närliggande grustäkter. Att handla från SDS stödjer lokala grustäkter och hjälper grusindustrin att blomstra.
          </ModalText>
          <Input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Ange postnummer"
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </div>
        <ButtonContainer>
          <Button onClick={handleForget}>Glöm mitt postnummer</Button>
          <Button onClick={handleSubmit}>Spara</Button>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default PostcodeModal;
