// src/components/Footer.js

import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.svg'; // Adjust the path according to your project structure

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  border-top: 1px solid #ccc;
  background-color: white
`;

const Logo = styled.img`
  height: 70px;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: #333;
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;

  &:hover {
    text-decoration: underline;
  }
`;

const Terms = styled.div`
  font-size: 14px;
  color: #666;
  text-align: center;
  margin-bottom: 15px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Logo src={logo} alt="Logo" />
      <LinksContainer>
        <Link href="/about">Om oss</Link>
        <Link href="/contact">Kontakt</Link>
      </LinksContainer>
      <Terms>Användarvillkor</Terms>
    </FooterContainer>
  );
};

export default Footer;
