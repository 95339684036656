// view/src/context/AuthContext.js

import React, {createContext, useState, useEffect, useContext} from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('https://nordicsoil.se/api/auth/profile', {
            headers: {Authorization: `Bearer ${token}`},
          });
          setUser(response.data.user);
        } catch (error) {
          console.error('Not authenticated', error);
          localStorage.removeItem('token'); // Remove token if invalid
        }
      }
      setLoading(false);
    };
    checkAuth();
  }, []);

  const login = async (username, password) => {
    try {
      const response = await axios.post('https://nordicsoil.se/api/auth/login', {username, password});
      localStorage.setItem('token', response.data.token); // Store token in localStorage
      setUser(response.data.user);
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  };

  const logout = async () => {
    localStorage.removeItem('token'); // Remove token from localStorage
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{user, login, logout, loading}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
